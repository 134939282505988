// import Button from '../../atoms/button/Button';
import './style.scss';

import { Button, Select, Spin } from 'antd';
import { Map } from 'ol';
import Slider from 'rc-slider';
import React, { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useQuery } from 'react-query';

import Input from '../../atoms/input/Input';
import TextArea from '../../atoms/input/Textarea';
import LoadingIndicator from '../../atoms/loadingIndicator/LoadingIndicator';
import { useGetCustomMaps } from '../../core/api/customMap/useGetCustomMaps';
import { getAllMapStyles, queryCities } from '../../core/api/WeatherAPI';
import { hexToRgba } from '../../helpers/hexToRgba';
import { useDebounce } from '../../hooks/useDebounce';
import { GVNSP_RING_WIDTH_MARKS } from '../../model/constants/constants';
import { projections } from '../../molecules/mapElement/projetions';
import { PaletteColorPicker } from '../../pages/playground/properties/mapLayersProperties/PalettecolorPicker';
import MapTypes from '../../pages/playground/properties/MapTypes';
import GridItem from '../../pages/playground/properties/shared/GridItem';
import { CustomMaps } from './CustomMaps';
import MapElementMap from './MapElementMapBaseMerc';

const { Option } = Select;

interface Props {
  editInput: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void;
  formValues: any;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
  step?: number;
  mapRef: React.MutableRefObject<Map | null>;
}

const NorthPoleProjections = ['ESRI:53026', 'ESRI:54032'];
const AddMapElement: React.FC<Props> = ({ editInput, formValues, setFormValues, step, mapRef }) => {
  const [city, setCity] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const [coordinates, setCoordinates] = useState<(number | undefined)[]>([undefined, undefined]);
  const debouncedQuery = useDebounce(city, 300);

  const isNsper = formValues?.projection === 'ESRI:54049';
  // const [step, setStep] = useState<1 | 2 | 3>(1);
  const { data: citiesList, isLoading: isLoadingCities } = useQuery(
    ['query-cities', debouncedQuery],
    () => queryCities(debouncedQuery),
    { cacheTime: Infinity, staleTime: Infinity, enabled: Boolean(debouncedQuery) },
  );
  const [query, setQuery] = useState('');
  const { data: mapData } = useQuery('all-map-types', () => getAllMapStyles(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    keepPreviousData: true,
  });
  const { data, isLoading } = useGetCustomMaps();
  useEffect(() => {
    setFormValues((fv: any) => ({
      ...fv,
      projection: 'EPSG:3857',
      // mapType: 'BASIC',
      north: true,
      zoom: 6,
    }));
  }, []);

  function onMapType(_: string, mapType: string, mapStyleId: string): void {
    setFormValues((fv: any) => ({ ...fv, mapType, mapStyleId }));
  }

  function onChckChange(ev: React.ChangeEvent<HTMLInputElement>): void {
    setFormValues((fv: any) => ({ ...fv, north: ev.target.checked }));
  }

  function onColorChange(ev: string, name: 'fromColor' | 'toColor') {
    setFormValues((fv: any) => ({ ...fv, [name]: ev }));
  }

  function onRingWidthChange(ev: number | number[]) {
    if (typeof ev !== 'number') return;
    setFormValues((fv: any) => ({ ...fv, ringWidth: ev }));
  }

  function getFilteredMaps(query: string, allMapTypes: any) {
    if (!query) {
      return allMapTypes;
    }
    return allMapTypes.filter((map: any) => {
      return map.name.includes(query);
    });
  }

  const filteredMaps = getFilteredMaps(query, mapData);

  const onHandleCoordinateChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    const input = parseFloat(e.target.value);
    const newCoordinates: (number | undefined)[] = [undefined, undefined];
    if (type === 'lon') {
      newCoordinates[0] = input;
      newCoordinates[1] = coordinates[1];
    } else {
      newCoordinates[0] = coordinates[0];
      newCoordinates[1] = input;
    }
    setCity('');
    setCoordinates(newCoordinates);
    if (
      (newCoordinates[0] || newCoordinates[0] == 0) &&
      (newCoordinates[1] || newCoordinates[1] == 0)
    ) {
      setFormValues((fv: any) => ({
        ...fv,
        center: [newCoordinates[0], newCoordinates[1]],
        osmId: null,
      }));
    } else {
      //
    }
  };

  const handleCityChange = (value: string, selectedCenter: any) => {
    setCoordinates([selectedCenter.lon, selectedCenter.lat]);
    setCity(selectedCenter.display_name);
    setFormValues((fv: any) => ({
      ...fv,
      center: [selectedCenter.lon, selectedCenter.lat],
      osmId: selectedCenter.osm_id,
    }));
  };

  const handleCitySearch = (city: string) => {
    setCity(city);
  };
  const changeMapType = () => setIsCustom((prev) => !prev);

  return (
    <div className={'form-body map-modal-body'}>
      {step === 2 ? (
        <div id="Input" className="mt-2 w-full flex flex-col">
          <div className="flex flex-col w-full">
            <div className="flex select-map-header">
              <div className="relative w-full" style={{ margin: '3px' }}>
                <input
                  type="text"
                  placeholder="Type here or Select map style below..."
                  onChange={(e) => setQuery(e.target.value.toUpperCase())}
                  disabled={!filteredMaps}
                  style={{ opacity: `${!filteredMaps ? '0.5' : '1'}` }}
                />
                <span
                  style={{
                    opacity: `${!filteredMaps ? '0.2' : '1'}`,
                    width: '14px',
                    right: ' 8px',
                    position: 'absolute',
                    top: '9px',
                  }}
                >
                  <AiOutlineSearch />
                </span>
              </div>
            </div>
            {filteredMaps && !isCustom ? (
              <>
                {filteredMaps && (
                  <MapTypes
                    currentMapType={formValues.mapType}
                    changeType={onMapType}
                    filteredMaps={
                      filteredMaps
                        ? filteredMaps.map((d: any) => ({
                            id: d.id,
                            imgSrc: d.thumbnailUrl,
                            imgSrcPreview: d.basicPreviewUrl,
                            name: d.name,
                          }))
                        : []
                    }
                  />
                )}
              </>
            ) : (
              !isCustom && (
                <div className="loading-indicator-wrapper">
                  <LoadingIndicator />
                </div>
              )
            )}
            {isCustom && (
              <CustomMaps
                data={data}
                currentMapType={formValues.mapType}
                changeType={onMapType}
                center={formValues.center}
              />
            )}
            {isCustom && isLoading && (
              <div className="loading-indicator-wrapper">
                <LoadingIndicator />
              </div>
            )}
          </div>
          <Button onClick={() => changeMapType()}>
            {!isCustom ? 'Custom Map' : 'Default Map'}
          </Button>
        </div>
      ) : null}
      {step === 1 ? (
        <>
          <Input
            name={'name'}
            label={'Name'}
            value={formValues.name || ''}
            onChange={(e) => editInput(e)}
            required
            type={'text'}
          />
          <TextArea
            name={'description'}
            label={'Description'}
            cols={2}
            value={formValues.description || ''}
            onChange={(e) => editInput(e)}
          />
          <div id="Input">
            <label className="text-sm">
              <span>*</span> Select projection:
            </label>
            <select
              value={formValues.projection}
              onChange={editInput}
              name="projection"
              className="w-full rounded-md"
              required
            >
              {projections.map((p) => (
                <option value={p.code} key={p.code}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
          {NorthPoleProjections.includes(formValues.projection) ? (
            <div className="flex items-center">
              <label className="text-base">North pole</label>
              <input checked={formValues.north} onChange={onChckChange} type={'checkbox'} />
            </div>
          ) : null}
          {isNsper && (
            <div className="AddMapElement__gvnsp-form">
              <div className="AddMapElement__gvnsp-form-item">
                <GridItem
                  noBorderBg
                  label={`Ring from color:`}
                  item={
                    <PaletteColorPicker
                      onChange={(color) => onColorChange(color, 'fromColor')}
                      value={hexToRgba(formValues?.fromColor) || 'rgba(0,0,0,255)'}
                      browserRGBA
                    />
                  }
                />
              </div>
              <div className="AddMapElement__gvnsp-form-item">
                <GridItem
                  noBorderBg
                  label={`Ring to color:`}
                  item={
                    <PaletteColorPicker
                      onChange={(color) => onColorChange(color, 'toColor')}
                      value={hexToRgba(formValues?.toColor) || 'rgba(0,0,0,255)'}
                      browserRGBA
                    />
                  }
                />
              </div>
              <div className="AddMapElement__gvnsp-form-item">
                <GridItem
                  noBorderBg
                  label={`Ring width:`}
                  itemStyle={{ flexGrow: 1 }}
                  item={
                    <Slider
                      marks={GVNSP_RING_WIDTH_MARKS}
                      min={30}
                      max={300}
                      onChange={onRingWidthChange}
                      value={formValues?.ringWidth ?? 30}
                    />
                  }
                />
              </div>
            </div>
          )}
          <div>
            <label className="text-sm relative" htmlFor="search-center">
              <span>*</span>Choose center:{' '}
            </label>
            <Select
              style={{ width: '100%' }}
              placeholder={'Type here...'}
              showSearch
              notFoundContent={
                isLoadingCities ? (
                  <Spin size="small" />
                ) : debouncedQuery ? (
                  <div>no results</div>
                ) : (
                  <div>type to search...</div>
                )
              }
              onSearch={handleCitySearch}
              onChange={handleCityChange}
              options={citiesList}
              fieldNames={{
                value: 'display_name',
              }}
              filterOption={false}
            >
              {citiesList?.map((item) => (
                <Option key={item.id}>
                  <div className="dropdown-item">{item.display_name}</div>
                </Option>
              ))}
            </Select>
          </div>
          <div id="Input">
            <span className={'flex gap-2'}>
              <div className={'flex-1'}>
                <label className="text-sm relative" htmlFor="search-center">
                  <span>*</span> Longitude:{' '}
                </label>
                <Input
                  type="number"
                  value={coordinates[0]}
                  placeholder={'Type here...'}
                  onChange={(e) => onHandleCoordinateChange(e, 'lon')}
                />
              </div>
              <div className={'flex-1'}>
                <label className="text-sm relative" htmlFor="search-center">
                  <span>*</span> Latitude:{' '}
                </label>
                <Input
                  type="number"
                  value={coordinates[1]}
                  placeholder={'Type here...'}
                  onChange={(e) => onHandleCoordinateChange(e, 'lat')}
                />
              </div>
            </span>
          </div>
        </>
      ) : null}

      {step === 3 ? (
        <MapElementMap
          center={formValues.center}
          mapType={formValues.mapType}
          projection={formValues.projection}
          setFormValues={setFormValues}
          formValues={formValues}
          mapRef={mapRef}
        />
      ) : null}
    </div>
  );
};

export default AddMapElement;
